import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { css } from 'emotion';
import { priceFormat } from './helpers';

const styles = css`
  position: sticky;
  top: 0;
  padding: 40px 0 0 0;
  .content {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    line-height: 1.5;
    .row {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .or {
    text-align: center;
    font-size: 1.375rem;
  }

  .total {
    margin-top: 50px;
    font-weight: 700;
    font-size: 16px;
  }
  .monthly-total {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #FAF9F7;
    margin: 0;
  }

  .section-collapsed {
    margin-bottom: 15px;
    padding-bottom: 0;
  }
`;

function Cart({ stateController, settings }) {
  const { lines, selectedProduct } = stateController.state;
  const { sections } = settings;
  return (
    <div className={styles}>
      {
        sections
          .filter(s => lines.find(l => (
            l.section === s.title
            && (!s.hideIncluded || !l.included)
          )))
          .concat(lines.filter(l => !l.section).map(l => ({ ...l, section: 'Other' })))
          .map(section => {
            const sectionLines = lines.filter(l => (
              l.section === section.title
              && (!section.hideIncluded || !l.included)
            ));

            if (stateController.sectionCollapsed(section.title)) {
              return (
                <div className="row section section-collapsed">
                  <div
                    role="button"
                    tabIndex="0"
                    className="col title"
                    onClick={stateController.collapseSection(section.title)}
                    onKeyUp={stateController.collapseSection(section.title)}
                  >
                    {section.title}
                  </div>
                  <div className="col-3 text-right">
                    {priceFormat(stateController.getTotal(sectionLines))}
                  </div>
                </div>
              );
            }

            return (
              <div className="section" key={section.title}>
                <div
                  role="button"
                  tabIndex="0"
                  className="title"
                  onClick={stateController.collapseSection(section.title)}
                  onKeyUp={stateController.collapseSection(section.title)}
                >
                  {section.title}
                </div>
                <div className="content">
                  {
                    sectionLines.map(line => {
                      const linePrice = stateController.getLinePrice(line);
                      return (
                        <div
                          className="row"
                          key={line.title}
                          onClick={stateController.removeLine(line)}
                          onKeyUp={stateController.removeLine(line)}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="col">{line.title}</div>
                          <div className="col-3 text-right">
                            { line.included && 'Inc.' }
                            { !linePrice && !line.monthly && !line.included && 'TBD'}
                            { !!linePrice && priceFormat(linePrice) }
                            { !!line.monthly && `${linePrice ? ' + ' : ''}
                            ${priceFormat(line.monthly)}/monthly` }
                          </div>
                        </div>
                      );
                    })
                  }
                  <div className="row">
                    <div className="col" />
                    <div className="col-4 text-right">
                      <b>Total: </b>
                      {priceFormat(stateController.getTotal(sectionLines))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
      }
      {
        selectedProduct
        && (
          <div className="section">
            <div className="title">Totals</div>
            <div className="content">
              {
                stateController.getDiscounts().length > 0
                && (
                <div className="row">
                  <div className="col">Subtotal</div>
                  <div className="col-3 text-right">{priceFormat(stateController.getTotal())}</div>
                </div>
                )
              }

              {
                stateController.getDiscounts().length > 0
                && [
                  <div key="title">Discounts</div>,
                  <ul className="mb-0" key="list">
                    {
                        stateController.getDiscounts().map(discount => (
                          <li key={discount.title}>
                            <div className="row discounts" onClick={stateController.removeDiscount(discount)} onKeyUp={stateController.removeDiscount(discount)} role="button" tabIndex={0}>
                              <div className="col">{discount.title}</div>
                              <div className="col-3 text-right">
                                -
                                {priceFormat(discount.price)}
                              </div>
                            </div>
                          </li>
                        ))
                      }
                  </ul>,
                ]
              }


              <div className="row total">
                <div className="col">Project Total</div>
                <div className="col-8 text-right">
                  {
                    !!(stateController.getTotal() - stateController.getDiscountsTotal())
                    && priceFormat(
                      stateController.getTotal() - stateController.getDiscountsTotal(),
                    )
                  }
                  {
                    !!stateController.getMonthlyTotal()
                    && `${stateController.getTotal() - stateController.getDiscountsTotal() ? ' + ' : ''}${priceFormat(stateController.getMonthlyTotal())}/month`
                  }
                </div>
              </div>

              {
                selectedProduct?.showSaaSCost
                && (
                  <div className="row monthly-total">
                    <div className="col"><em>Monthly Payment Option</em></div>
                    <div className="col-6 text-right">
                      {
                        priceFormat(
                          (
                            (
                              (stateController.getTotal() - stateController.getDiscountsTotal())
                              * settings.sass.multiplier
                            ) / settings.sass.termMonths)
                            + stateController.getMonthlyTotal(),
                        )
                      }
                      /month
                    </div>
                  </div>
                )
              }

              {
                lines.filter(line => (
                  !stateController.getLinePrice(line) && !line.included
                )).length > 0
                && (
                <div className="mt-3">
                  Your quote contains items marked as &quot;To Be Determined&quot;.
                  {' '}
                  Final price will be calculated when all information has been reviewed.
                </div>
                )
              }
            </div>
          </div>
        )
      }
      {
        settings.showCosts
        && (
          <div className="section">
            <div className="title">Costs</div>
            <div className="content">
              {
                stateController.getTotalHours().map(line => (
                  <div className="row" key={line.title}>
                    <div className="col">{line.title}</div>
                    <div className="col-3">
                      {numeral(line.hours).format('0.00')}
                      &nbsp;hours
                    </div>
                    <div className="col-3 text-right">
                      {priceFormat(line.cost)}
                    </div>
                  </div>
                ))
              }
              <div className="row total">
                <div className="col">Project Cost</div>
                <div className="col-8 text-right">
                  {
                    !!(stateController.getTotalCost())
                    && priceFormat(stateController.getTotalCost())
                  }
                </div>
              </div>

              <div className="row">
                <div className="col">Margin</div>
                <div className="col-8 text-right">
                  {
                    numeral(((stateController.getTotal() - stateController.getDiscountsTotal()) - stateController.getTotalCost()) / (stateController.getTotal() - stateController.getDiscountsTotal())).format('0%')
                }
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="row">
        <div className="col text-right">
          <button type="button" className="btn" onClick={stateController.save}>Save</button>
        </div>
      </div>
    </div>
  );
}

Cart.propTypes = {
  stateController: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

Cart.defaultProps = {
};

export default Cart;
