import
{
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';

import { getAuthToken } from './Authentication';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_SERVER_URI ?? 'https://sales.builderdesigns.com/api',
});

const errorLink = onError(() => {});

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    authLink,
    errorLink,
    httpLink,
  ]),
});

export default client;
