import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { priceFormat, getFeatureSuffix } from './helpers';

const styles = css``;

function Sections({ stateController, sections }) {
  return (
    <div className={styles}>
      {
        sections.map(section => {
          const features = (section.features || []).filter(feature => (
            !feature.ifProduct
            || (stateController.featureSelectedById(feature.ifProduct))
          ));

          if (features.length === 0) {
            return null;
          }

          return (
            <div className="section" key={section.title}>
              <div className="title">{section.title}</div>
              <div className="content">
                {
                  features.map(feature => {
                    const featurePrice = stateController.getLinePrice({ ...feature, qty: 1 });
                    const featureMonthly = stateController.getLineMonthlyPrice(feature);
                    return (
                      <div className="row" key={feature.title} onClick={stateController.selectFeature(feature, section)} onKeyUp={stateController.selectFeature(feature, section)} role="button" tabIndex={0}>
                        <div className="col">
                          <input type="checkbox" readOnly checked={stateController.featureSelected(feature, section)} />
                          {feature.title}
                        </div>
                        <div className="col-3 text-right">
                          { !featurePrice && !featureMonthly && 'TBD'}
                          { !!featurePrice && `${priceFormat(featurePrice)}${getFeatureSuffix(feature)}` }
                          { !!featureMonthly && `${featurePrice ? ' + ' : ''}${priceFormat(featureMonthly)}/monthly` }
                        </div>
                        <div className="col-2">
                          {
                            feature.perQuantity
                            && stateController.featureSelected(feature, section)
                              && (
                              <input
                                className="form-control"
                                placeholder="Qty"
                                type="number"
                                value={stateController.getInput(`${section.title}-${feature.title}-qty`)}
                                onClick={e => e.stopPropagation()}
                                onKeyUp={e => e.stopPropagation()}
                                onChange={stateController.setFeatureQty(`${section.title}-${feature.title}-qty`, feature, section)}
                              />
                              )
                          }
                        </div>
                      </div>
                    );
                  })
                }
                {
                  section.other
                  && (
                  <div className="row other">
                    <div className="col-1">Other</div>
                    <div className="col-9"><input placeholder="Name" className="form-control" type="text" value={stateController.getInput(`other${section.title}`)} onChange={stateController.setInput(`other${section.title}`)} /></div>
                    <div className="col-2"><button type="button" className="btn add-btn" onClick={stateController.addOtherFeature(`other${section.title}`, section)}>Add</button></div>
                  </div>
                  )
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

Sections.propTypes = {
  stateController: PropTypes.object.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Sections.defaultProps = {
};

export default Sections;
