import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { priceFormat, getFeatureSuffix } from './helpers';

const styles = css``;

function Discounts({ stateController, discounts }) {
  return (
    <div className={styles}>
      <div className="section">
        <div className="title">Discounts</div>
        <div className="content">
          {
            discounts.filter(discount => !discount.auto).map(discount => {
              const discountPrice = stateController.getDiscountPrice(discount);

              return (
                <div className="row" key={discount.title} onClick={stateController.selectDiscount(discount)} onKeyUp={stateController.selectDiscount(discount)} role="button" tabIndex={0}>
                  <div className="col">
                    <input type="checkbox" readOnly checked={stateController.discountSelected(discount)} />
                    {discount.title}
                  </div>
                  <div className="col-3 text-right">
                    { !discountPrice && !discount.monthly && 'TBD'}
                    { !!discountPrice && `-${priceFormat(discountPrice)}${getFeatureSuffix(discount)}` }
                    { !!discount.monthly && `${discountPrice ? ' + ' : ''}${priceFormat(discount.monthly)}/monthly` }
                  </div>
                  <div className="col-2">
                    {
                      discount.perQuantity
                      && stateController.discountSelected(discount)
                        && (
                        <input
                          placeholder="Qty"
                          type="number"
                          value={stateController.state[`discounts-${discount.title}-qty`] ?? ''}
                          onClick={e => e.stopPropagation()}
                          onKeyUp={e => e.stopPropagation()}
                          onChange={stateController.setFeatureQty(`discounts-${discount.title}-qty`, discount)}
                        />
                        )
                    }
                  </div>
                </div>
              );
            })
          }
          <div className="row other">
            <div className="col-1">Other</div>
            <div className="col-6"><input className="form-control" type="text" value={stateController.getInput('otherdiscounts')} onChange={stateController.setInput('otherdiscounts')} placeholder="Description" /></div>
            <div className="col-3"><input className="form-control" type="number" value={stateController.getInput('otherdiscounts-amount')} onChange={stateController.setInput('otherdiscounts-amount')} placeholder="Amount" /></div>
            <div className="col-2"><button type="button" className="btn add-btn" onClick={stateController.addOtherDiscount('otherdiscounts')}>Add</button></div>
          </div>
        </div>
      </div>
    </div>
  );
}

Discounts.propTypes = {
  stateController: PropTypes.object.isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Discounts.defaultProps = {
};

export default Discounts;
