import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import useAuth from '../services/Authentication';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  if (user === false) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props => (user
        ? <Component {...props} />
        : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ))}
    />
  );
};

AuthenticatedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func.isRequired,
};

AuthenticatedRoute.defaultProps = {
  location: undefined,
};

export default AuthenticatedRoute;
