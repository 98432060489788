import _ from 'lodash';

class StateController {
  constructor({
    state, storeState, settings, save,
  }) {
    this.state = state;
    this.storeState = storeState;
    this.settings = settings;
    this.save = save;
  }

  selectProduct = selectedProduct => () => {
    const { lines } = this.state;

    const newLines = lines.filter(line => !line.product); // Remove existing product costs
    newLines.push(...selectedProduct.baseCosts.map(cost => ({ ...cost, product: true, section: 'Selected Product' }))); // Add selectedProductCosts
    this.storeState({
      selectedProduct,
      lines: newLines,
    });
  }

  selectPage = page => () => {
    const { lines, selectedProduct } = this.state;
    const pageId = `page-${page.title}`;
    const existingIndex = lines.findIndex(f => f.id === pageId);

    if (existingIndex > -1) {
      lines.splice(existingIndex, 1);
    } else {
      const pageLine = {
        ...page,
        section: !page.included ? 'Optional Pages' : 'Included Pages',
        costs: selectedProduct?.costs?.page,
        discountGroup: !page.included ? 'optional-pages' : undefined,
        id: pageId,
      };
      lines.push(pageLine);
    }

    this.storeState({
      lines,
    });
  }

  pageSelected = page => {
    const { lines } = this.state;
    const pageId = `page-${page.title}`;
    const existingIndex = lines.findIndex(f => f.id === pageId);

    return existingIndex > -1;
  }

  addOtherPage = () => () => {
    const otherPage = this.getInput('otherPage');

    if (!otherPage) {
      return;
    }

    const page = {
      title: otherPage,
    };

    this.selectPage(page)();
    this.setInput('otherPage')({ target: { value: '' } });
  }

  selectFeature = (feature, section) => () => {
    const featureId = `${section.title}-${feature.title}`;
    const { lines } = this.state;
    const cartFeature = {
      ...feature,
      id: featureId,
      section: section.title,
      discount: section.discount,
    };

    if (feature.perQuantity && this.state[`${cartFeature.id}-qty`]) {
      cartFeature.qty = this.state[`${cartFeature.id}-qty`];
    }

    const existingIndex = lines.findIndex(f => f.id === cartFeature.id);

    if (existingIndex > -1) {
      lines.splice(existingIndex, 1);
    } else {
      lines.push(cartFeature);
    }

    this.storeState({
      lines,
    });
  }

  featureSelected = (feature, section) => {
    const { lines } = this.state;
    const existingIndex = lines.findIndex(f => f.id === `${section.title}-${feature.title}`);

    return existingIndex > -1;
  }

  featureSelectedById = id => {
    const { lines } = this.state;
    const existingIndex = lines.findIndex(f => f.uniqueId === id);

    return existingIndex > -1;
  }

  getLinePrice = (line, rateType = 'billed') => {
    const { lines } = this.state;
    let value = line.price ?? 0;

    if (line.included && rateType === 'billed') {
      return 0;
    }

    if (line.priceGroup) {
      const priceGroup = this.settings.priceGroups
        .find(pg => pg.id === line.priceGroup) ?? { calculate: () => value };
      value = priceGroup.calculate(this.state, lines.filter(l => l.priceGroup === line.priceGroup));
    }

    if (line.costs) {
      const linePrice = line.costs.reduce((acc, cost) => {
        let price = cost.price ?? 0;

        if (!price && cost.rate && cost.hours) {
          price = (this.settings.rates[cost.rate]?.[rateType] ?? 0) * cost.hours;
        }

        return acc + price;
      }, 0);

      value = linePrice;
    }

    if (line.perQuantity) {
      value *= Math.max(0, (line.qty ?? 0) - (line.qtyIncluded ?? 0));
    }

    return Number.isNaN(value) ? 0 : value;
  }

  addOtherFeature = (field, section) => () => {
    const title = this.getInput(field);
    const amount = this.getInput(`${field}-amount`);
    const { lines } = this.state;

    const feature = {
      title,
      price: parseInt(amount, 10) ?? section?.other?.price ?? undefined,
      section: section.title,
      discount: section.discount,
      id: `${section.title}-${title}`,
    };

    lines.push(feature);

    this.storeState({
      lines,
    });
  }

  setFeatureQty = (field, feature, section) => e => {
    const { lines } = this.state;
    const value = parseInt(e.target.value, 10);
    const cartFeature = lines.find(cf => cf.id === `${section.title}-${feature.title}`);

    if (cartFeature) {
      cartFeature.qty = value;
    }

    this.storeState({
      lines,
      inputFields: {
        ...this.state.inputFields,
        [field]: value,
      },
    });
  }

  removeLine = line => () => {
    if (!line || line.product || line.amCost) {
      return;
    }

    const { lines } = this.state;

    lines.splice(lines.indexOf(line), 1);

    this.storeState({
      lines,
    });
  }

  getTotal = (lines = this.state.lines) => {
    // const { lines } = this.state;
    let total = 0;

    total += lines.reduce((acc, line) => acc + this.getLinePrice(line), 0);

    return total;
  }

  getTotalCost = (lines = this.state.lines) => {
    // const { lines } = this.state;
    let total = 0;

    total += lines.reduce((acc, line) => acc + this.getLinePrice(line, 'cost'), 0);

    return total;
  }

  getTotalHours = (lines = this.state.lines) => {
    // const { lines } = this.state;
    const hours = lines.reduce((acc, line) => {
      (line.costs || []).forEach(cost => {
        if (!cost.rate) {
          return;
        }

        const qty = line.qty ?? 1;

        if (!acc[cost.rate]) {
          acc[cost.rate] = 0;
        }

        acc[cost.rate] += cost.hours * qty;
      });
      return acc;
    }, {});

    return Object.keys(hours).map(rate => ({
      title: this.settings.rates[rate].label,
      hours: hours[rate],
      cost: this.settings.rates[rate].cost * hours[rate],
    }));
  }

  getDiscountPrice = discount => {
    const { lines } = this.state;
    const discountItems = lines.filter(line => line.discountGroup === discount.id);
    let price = 0;

    if (discount.percentage) {
      const totalPrice = discountItems.reduce((acc, line) => acc + this.getLinePrice(line), 0);
      price = ((discount.percentage / 100) * totalPrice);
    } else if (discount.calculate) {
      price = discount.calculate(this.state, discountItems);
    }

    return price;
  }

  getDiscounts = () => {
    const { discountLines = [] } = this.state;
    const displayDiscounts = [];
    const selectedIds = discountLines.map(line => line.id);

    this.settings.discounts
      .filter(discount => discount.auto || selectedIds.includes(discount.id))
      .forEach(discount => {
        const price = this.getDiscountPrice(discount);

        displayDiscounts.push({ ...discount, price });
      });

    displayDiscounts.push(...discountLines.filter(discount => discount.other));

    return displayDiscounts.filter(discount => discount.price);
  }

  setAccountManagementCosts = () => {
    const {
      client: {
        communities,
        homes,
        plans,
        areas,
      } = {},
      lines,
    } = this.state;

    const discountGroup = 'inventory-data-entry';
    const section = 'Content Loading Services';

    const newLines = lines.filter(line => !line.amCost); // Remove existing am costs

    const amCosts = [
      {
        title: 'Community Loading',
        qty: communities,
        qtyIncluded: 5,
        hours: 0.5,
      },
      {
        title: 'Plan Loading',
        qty: plans,
        qtyIncluded: 20,
        hours: 0.5,
      },
      {
        title: 'Home Loading',
        qty: homes,
        qtyIncluded: 20,
        hours: 0.5,
      },
      {
        title: 'Area Loading',
        qty: Math.floor(areas / 10),
        hours: 10,
      },
    ];

    newLines.push(
      ...amCosts
        .map(line => ({
          ...line,
          section,
          discountGroup,
          amCost: true,
          perQuantity: true,
          included: line.qty <= line.qtyIncluded,
          costs: [
            {
              rate: 'dataEntry',
              hours: line.hours,
            },
          ],
        }))
        .filter(line => line.qty),
    );

    this.storeState({
      lines: newLines,
    });
  }

  getDiscountsTotal = () => {
    return this.getDiscounts().reduce((acc, discount) => acc + (discount.price ?? 0), 0);
  }

  getLineMonthlyPrice = ({ costs = [] }) => costs.reduce((acc, cost) => {
    const price = cost.monthly ?? 0;

    return acc + price;
  }, 0);

  getMonthlyTotal = () => {
    const { lines, selectedProduct } = this.state;
    let total = this.getLineMonthlyPrice({ costs: selectedProduct?.costs?.baseMonthly });
    total += lines.reduce((acc, line) => acc + this.getLineMonthlyPrice(line), 0);

    return total;
  }

  getInput = field => _.get(this.state, field) ?? _.get(this.state, `inputFields.${field}`) ?? ''

  setInput = (field, root) => event => {
    const { value } = event.target;
    const newState = {
      inputFields: { ...this.state.inputFields },
      client: { ...this.state.client },
    };

    if (root) {
      _.set(newState, field, value);
    } else {
      _.set(newState, `inputFields.${field}`, value);
    }

    this.storeState(newState);
  }

  selectDiscount = discount => () => {
    const { discountLines } = this.state;

    if (discountLines.includes(discount)) {
      discountLines.splice(discountLines.indexOf(discount), 1);
    } else {
      discountLines.push(discount);
    }


    this.storeState({
      discountLines,
    });
  }

  discountSelected = discount => {
    const { discountLines = [] } = this.state;

    return discountLines.includes(discount);
  }

  addOtherDiscount = field => () => {
    const title = this.getInput(field);
    const amount = parseInt(this.getInput(`${field}-amount`), 10);
    const { discountLines = [] } = this.state;

    const discount = {
      title,
      price: !Number.isNaN(amount) ? amount : undefined,
      id: `${title}`,
      other: true,
    };

    discountLines.push(discount);

    this.storeState({
      discountLines,
      [field]: '',
      [`${field}-amount`]: '',
    });
  }

  removeDiscount = discount => () => {
    const { discountLines } = this.state;

    if (discountLines.includes(discount)) {
      discountLines.splice(discountLines.indexOf(discount), 1);
    }

    this.storeState({
      discountLines,
    });
  }

  setCost = cost => e => {
    const { selectedProduct } = this.state;
    const value = parseInt(e.target.value, 10);

    selectedProduct.costs[cost.key] = Number.isNaN(value) ? '' : value;

    this.storeState({
      selectedProduct,
    });
  }

  save = () => {
    this.save(this.state);
  }

  sectionCollapsed = section => {
    return !!this.state.collapsed?.[section];
  }

  collapseSection = section => () => {
    const { collapsed } = this.state;
    this.storeState({
      collapsed: {
        ...collapsed,
        [section]: !this.sectionCollapsed(section),
      },
    });
  }
}

export default StateController;
