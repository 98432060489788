import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import _ from 'lodash';
import { priceFormat } from './helpers';

const styles = css`
  border-right: 1px solid #DDDDDD;
  padding: 40px 30px 0 0;
  margin: 0 0 0 0;
  .content {
    padding: 20px;
    background-color: #FAF9F7;
    border-radius: 4px;
    line-height: 1.5;
    .row {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .example-header {
    margin: 32px 0 16px 0;
    font-size: 16px;
    font-weight: 700;
  }

  .selectedProduct {
    color: #999999;
    padding: 20px;
    border-radius: 4px;
    margin: 0 0 16px 0;
    transition: all .3s ease;
    background-color: #F1EEE9;
    font-weight: 300;
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      color: #000;
      background-color: #FFFFFF;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    }
  }
`;

function Products({ products, selectedProduct, stateController }) {
  const selectedModel = _.get(selectedProduct, 'models', []).find(model => model.title === stateController.getInput('model'));

  return (
    <div className={styles}>
      <div className="section">
        <div className="title">Products</div>
        <div className="row">
          <div className="col-7">
            <div className="content">
              <ul className="list-unstyled">
                {
                  products.map(product => (
                    <li key={product.title} className={`py-3 ${selectedProduct ? 'selectedProduct' : ''} ${selectedProduct?.id === product.id ? 'active' : ''}`}>
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={stateController.selectProduct(product)}
                        onKeyUp={stateController.selectProduct(product)}
                      >
                        <div className="row">
                          <div className="col">
                            <div className="title">{product.title}</div>
                          </div>
                          <div className="col">
                            {`Starting At ${priceFormat(stateController.getTotal(product.baseCosts))}`}
                          </div>
                        </div>
                        {
                          product.overview.map(item => (
                            <div className="row" key={item}>
                              <div className="col">{item}</div>
                            </div>
                          ))
                        }
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          {
            selectedProduct
            && (
              <div className="col-5">
                <img src="/images/design-center-header.png" className="w-100 mb-4" alt="" />
                <select id="template" name="template" value={stateController.getInput('model')} onChange={stateController.setInput('model')}>
                  <option value="">Select Model</option>
                  {
                    (selectedProduct.models ?? []).map(model => (
                      <option key={model.title}>{model.title}</option>
                    ))
                  }
                </select>
                {
                  selectedModel
                  && (
                    <>
                      <h5 className="example-header">{`View Examples of the ${selectedModel.title}`}</h5>
                      <ul className="list-unstyled m-0">
                        {
                          (selectedModel.examples ?? []).map(example => (
                            <li key={example.href}><a href={example.href} target="_blank" rel="noopener noreferrer">{example.title}</a></li>
                          ))
                        }
                      </ul>
                    </>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  stateController: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object,
};

Products.defaultProps = {
  selectedProduct: undefined,
};

export default Products;
