import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Redirect } from 'react-router-dom';
import useAuth from '../services/Authentication';

const styles = css`
  padding-top: 25vh;
  display: flex;
  justify-content: center;
  .LoginContainer {
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    border-radius: 4px;
    padding: 48px;
    width: 600px;
  }

  .LoginContainer_buttonContainer {
    padding-top: 25px;
    display: flex;
    justify-content: center;

    .btn-signin {
        background-color: #AA9778;
        padding: 5px 12px;
        font-size: 13px;
        font-weight: 700;
        color: #FFFFFF;
        letter-spacing: 1px;
        text-transform: uppercase;
        vertical-align: top;
      }
  }
`;

function Login({ location }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [quoteToken, setQuoteToken] = useState('');

  const { user, login } = useAuth();

  if (user) {
    return (
      <Redirect
        to={location.state?.from?.pathname ?? '/'}
      />
    );
  }

  return (
    <div className={styles}>
      <div className="LoginContainer">
        <form onSubmit={e => { login(username, password); e.preventDefault(); }}>
          <input type="text" className="form-control" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
          <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <div className="LoginContainer_buttonContainer">
            <button type="submit" className="btn btn-signin">Sign In</button>
          </div>
        </form>

        <form className="mt-5">
          <input className="form-control" type="text" placeholder="Quote Indentifier" value={quoteToken} onChange={e => setQuoteToken(e.target.value)} />
          <div className="LoginContainer_buttonContainer">
            <button type="button" className="btn btn-signin">View Quote</button>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Login;
