import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { useApolloClient, gql } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';
import StateController from './StateController';
import Products from './Products';
import BuilderInfo from './BuilderInfo';
import Cart from './Cart';
import Pages from './Pages';
import Sections from './Sections';
import Discounts from './Discounts';

import * as defaultSettings from '../../products';

const styles = css`
  *:focus {
    outline: none;
  }
  *[role=button] {
    cursor: pointer;
  }
  strong {
    font-weight: 900;
  }
  a {
    color: #AA9778;
    &:hover {
      color: #222222;
    }
  }
  font-size: .875rem;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 72px 0 160px 0;
  height: auto;
  position: relative;
  h1 {
    font-weight: 700;
    font-size: 2rem;
    padding: 0 0 20px 0;
    margin: 0 0 0 0;
    border-bottom: 1px solid #DDDDDD;
    background-image: url('/images/bd_logo_black.svg');
    background-position: right top;
    background-size: 195px auto;
    background-repeat: no-repeat;
  }
  .container {
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    border-radius: 4px;
    padding: 48px;
    transition: all .3s ease;
    @media (min-width: 1600px) {
      max-width: 1400px;
    }
    @media (min-width: 1800px) {
      max-width: 1600px;
    }
  }

  .section {
    margin-bottom: 32px;
    padding-bottom: 24px;

    &:last-of-type {
      border-bottom: none;
    }
    .title {
      font-size: .875rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 0 12px 0;
      color: #AA9778;
    }

    input[type=text], input[type=number], select {
      width: 100%;
      padding: 8px;
      border: 1px solid #F1EEE9;
      margin: 0 0 0 0;
      line-height: 1;
      border-radius: 4px;
      background-color: #FFFFFF;
      background-image: none;
    }

    input[type=checkbox] {
      margin: 0 6px 0 0;
    }

    .other {
      padding-top: 16px;
      margin-top: 16px !important;
      border-top: 1px solid #CCCCCC;
      line-height: 34px;
      input[type=text] {
        line-height: 1 !important;
      }
      .add-btn {
        background-color: #AA9778;
        padding: 5px 12px;
        font-size: 13px;
        font-weight: 700;
        color: #FFFFFF;
        letter-spacing: 1px;
        text-transform: uppercase;
        vertical-align: top;
        &::before {
          content: '+ '
        }
      }
    }
  }

`;

const GET_QUOTE = gql`
  query GET_QUOTE($_id: ID) {
    getQuote(_id: $_id) {
      _id
      selectedProduct {
        id
        title
        overview
        baseCosts {
          title
          costs {
            rate
            hours
            price
            monthly
          }
        }
        costs
        showSaaSCost
      }
      client {
        name
        communities
        plans
        homes
        areas
      }
      lines {
        id
        uniqueId
        title
        qty
        product
        included
        section
        discountGroup
        perQuantity
        perLabel
        costs {
          rate
          hours
          price
          monthly
        }
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation UPDATE_QUOTE($_id: ID, $quote: QuoteInput) {
    updateQuote(_id: $_id, input: $quote) {
      _id
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation CREATE_QUOTE($quote: QuoteInput) {
    createQuote(input: $quote) {
      _id
    }
  }
`;

function Calculator({ match, history }) {
  const [state, setState] = useState({
    selectedProduct: null,
    client: {
      communities: 5,
      plans: 20,
      homes: 20,
      areas: 5,
      name: '',
    },
    lines: [],
    discountLines: [],
    collapsed: {},
    inputFields: {},
    settings: { ...defaultSettings },
  });
  const client = useApolloClient();

  useEffect(() => {
    if (match?.params?.quoteId && !state._id) {
      (async () => {
        const { data: { getQuote } = {} } = await client.query({
          query: GET_QUOTE,
          variables: {
            _id: match?.params?.quoteId,
          },
        });

        const savedState = omitDeep(getQuote, '__typename');

        setState({
          ...savedState,
          settings: { ...defaultSettings, ...savedState.settings },
        });
      })();
    }
  });

  const save = async () => {
    if (match?.params?.quoteId) {
      const { _id, ...mutateState } = state;
      await client.mutate({
        mutation: UPDATE_QUOTE,
        variables: { _id: state._id, quote: mutateState },
      });
    } else {
      const { data: { createQuote } } = await client.mutate({
        mutation: CREATE_QUOTE,
        variables: { quote: state },
      });

      setState({ _id: createQuote._id, ...state });
      history.replace(`/quote/${createQuote._id}`);
    }
  };

  const storeState = updates => {
    setState({
      ...state,
      ...updates,
    });
  };

  const stateController = new StateController({
    state, storeState, save, settings: state.settings,
  });

  return (
    <div className={styles}>
      {
        (
          (match?.params?.quoteId && state._id)
          || !match?.params?.quoteId
        )
        && (
          <div className="container">
            <header>
              <h1>Website Cost Calculator</h1>
            </header>
            <div className="row">
              <div className="col-8">
                <Products
                  selectedProduct={state.selectedProduct}
                  stateController={stateController}
                  products={state.settings.products}
                />
                {
                  state.selectedProduct
                  && (
                    <>
                      <BuilderInfo stateController={stateController} />
                      <Pages stateController={stateController} pages={state.settings.pages} />
                      <Sections
                        stateController={stateController}
                        sections={state.settings.sections}
                      />
                      <Discounts
                        stateController={stateController}
                        discounts={state.settings.discounts}
                      />
                    </>
                  )
                }
              </div>
              <div className="col">
                <Cart stateController={stateController} settings={state.settings} />
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

Calculator.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Calculator;
