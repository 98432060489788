import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { css } from 'emotion';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../services/apollo';
import AuthenticatedRoute from './AuthenticatedRoute';
import Calculator from './Calculator';
import Login from './Login';
import NoMatch from './NoMatch';
import Nav from './Nav';
import Quotes from './Quotes';
import 'bootstrap/dist/css/bootstrap.css';
import { ProvideAuth } from '../services/Authentication';

const backgroundStyles = css`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  background: url('/images/web-develop-bg.jpg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 700px;
    right: 0;
    top: -500px;
    background-color: #AA9778;
    transform: skewY(25deg);
    opacity: .85;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 5000px;
    left : 0;
    top: 200px;
    background-color: #3F3F3F;
    transform: skewY(25deg);
    opacity: .85;
  }
`;

function App() {
  return (
    <div>
      <div className={backgroundStyles} />
      <ApolloProvider client={apolloClient}>
        <ProvideAuth>
          <Router>
            <Nav />
            <Switch>
              <AuthenticatedRoute exact path="/" component={Quotes} />
              <AuthenticatedRoute exact path="/quote/new" component={Calculator} />
              <AuthenticatedRoute exact path="/quote/:quoteId" component={Calculator} />
              <Route exact path="/login" component={Login} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </ProvideAuth>
      </ApolloProvider>
    </div>
  );
}

export default App;
