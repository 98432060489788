import numeral from 'numeral';

export const getFeatureSuffix = feature => {
  let suffix = '';

  if (feature.perQuantity) {
    suffix = `/${feature.perLabel ?? 'each'}`;
  }

  return suffix;
};

export const priceFormat = number => numeral(number).format('$0,0');
