import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import useAuth from '../services/Authentication';

const styles = css`
  display: flex;
  justify-content: right;
  .User {
    color: #FFF;
    .User_name {
      font-style: italic;
    }
  }
  [role=button] {
    cursor: pointer;
  }
`;

function Nav() {
  const { user, logout } = useAuth();

  return (
    <div className={styles}>
      <nav>
        {
          user
          && <Link to="/">Quotes</Link>
        }
      </nav>
      {
        user
        && (
        <div className="User">
          {'Hi, '}
          <span className="User_name">
            {user?.name?.split(' ')[0]}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={e => logout(e)}
            onKeyDown={e => logout(e)}
            className="User_logout"
          >
            {' Logout'}
          </span>
        </div>
        )
      }
    </div>
  );
}

export default Nav;
