import React from 'react';
import moment from 'moment';
import { css } from 'emotion';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const styles = css`
  font-size: .875rem;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 72px 0 160px 0;
  height: auto;
  position: relative;

  .container {
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    border-radius: 4px;
    padding: 48px;
    transition: all .3s ease;
    @media (min-width: 1600px) {
      max-width: 1400px;
    }
    @media (min-width: 1800px) {
      max-width: 1600px;
    }
  }
`;

const GET_QUOTES = gql`
  query GET_QUOTES {
    getQuotes {
      _id
      client {
        name
      }
    }
  }
`;

function Quotes() {
  const { loading, data } = useQuery(GET_QUOTES);

  return (
    <div className={styles}>
      <div className="container">
        {
          loading
          && (
            <span>Loading...</span>
          )
        }
        <Link to="/quote/new">New Quote</Link>
        <table className="table">
          <thead>
            <tr>
              <th>Client</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              (data?.getQuotes ?? []).map(quote => (
                <tr>
                  <td><Link to={`/quote/${quote._id}`}>{quote.client?.name}</Link></td>
                  <td>{moment(parseInt(quote._id.substring(0, 8), 16) * 1000).format('MMM DD, YYYY')}</td>
                  <td><Link to={`/quote/${quote._id}`}>Edit</Link></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Quotes;
