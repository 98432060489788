import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const styles = css``;

function BuilderInfo({ stateController }) {
  const counts = {
    communities: stateController.getInput('client.communities'),
    plans: stateController.getInput('client.plans'),
    homes: stateController.getInput('client.homes'),
    areas: stateController.getInput('client.areas'),
  };

  useEffect(() => {
    stateController.setAccountManagementCosts();
  }, [counts.communities, counts.plans, counts.homes, counts.areas]); // eslint-disable-line

  return (
    <div className={styles}>
      <div className="section" key="Builder Info">
        <div className="title">Builder Information</div>
        <div className="content">
          <ul className="list-unstyled">
            <li>
              <div className="row">
                <div className="col">Builder Name</div>
                <div className="col-2 text-right"><input type="text" value={stateController.getInput('client.name')} onChange={stateController.setInput('client.name', true)} /></div>
                <div className="col-2" />
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col">Number Of Communities</div>
                <div className="col-2 text-right"><input type="number" value={counts.communities} onChange={stateController.setInput('client.communities', true)} /></div>
                <div className="col-2" />
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col">Number Of Plans</div>
                <div className="col-2 text-right"><input type="number" value={counts.plans} onChange={stateController.setInput('client.plans', true)} /></div>
                <div className="col-2" />
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col">Number Of Homes</div>
                <div className="col-2 text-right"><input type="number" value={counts.homes} onChange={stateController.setInput('client.homes', true)} /></div>
                <div className="col-2" />
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col">Number Of Market Areas</div>
                <div className="col-2 text-right"><input type="number" value={counts.areas} onChange={stateController.setInput('client.areas', true)} /></div>
                <div className="col-2" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

BuilderInfo.propTypes = {
  stateController: PropTypes.object.isRequired,
};

BuilderInfo.defaultProps = {
};

export default BuilderInfo;
