import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { priceFormat } from './helpers';

const styles = css``;

function Pages({ stateController, pages }) {
  const { selectedProduct } = stateController.state;

  return (
    <div className={styles}>
      <div className="section" key="Included Pages">
        <div className="title">Included Pages</div>
        <div className="content">
          {
            pages.filter(page => page.included).map(page => (
              <div className="row" key={page.title} onClick={stateController.selectPage(page)} onKeyUp={stateController.selectPage(page)} role="button" tabIndex={0}>
                <div className="col">
                  <input type="checkbox" readOnly checked={stateController.pageSelected(page)} />
                  {page.title}
                </div>
                <div className="col-3 text-right">Included</div>
                <div className="col-2" />
              </div>
            ))
          }
        </div>
      </div>
      <div className="section" key="Optional Pages">
        <div className="title">Optional Pages</div>
        <div className="content">
          {
            pages.filter(page => !page.included).map(page => (
              <div className="row" key={page.title} onClick={stateController.selectPage(page)} onKeyUp={stateController.selectPage(page)} role="button" tabIndex={0}>
                <div className="col">
                  <input type="checkbox" readOnly checked={stateController.pageSelected(page)} />
                  {page.title}
                </div>
                <div className="col-3 text-right">{selectedProduct ? priceFormat(stateController.getLinePrice({ costs: selectedProduct?.costs?.page })) : ''}</div>
                <div className="col-2" />
              </div>
            ))
          }

          <div className="row other">
            <div className="col-1">Other</div>
            <div className="col-8"><input placeholder="Name" className="form-control" type="text" value={stateController.getInput('otherPage')} onChange={stateController.setInput('otherPage')} /></div>
            <div className="col-1 text-right">{selectedProduct ? priceFormat(stateController.getLinePrice({ costs: selectedProduct?.costs?.page })) : ''}</div>
            <div className="col"><button type="button" className="btn add-btn" onClick={stateController.addOtherPage()}>Add</button></div>
          </div>
        </div>
      </div>
    </div>
  );
}

Pages.propTypes = {
  stateController: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Pages.defaultProps = {
};

export default Pages;
