import React from 'react';
import { css } from 'emotion';

const styles = css`
  color: #FFFFFF;
`;

function NoMatch() {
  return (
    <div className={styles}>No Page Found</div>
  );
}

export default NoMatch;
