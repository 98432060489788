export const rates = {
  development: {
    label: 'Development',
    billed: 150,
    cost: 40,
  },
  dataEntry: {
    label: 'Data Entry',
    billed: 100,
    cost: 30,
  },
  design: {
    label: 'Design',
    billed: 150,
    cost: 50,
  },
};

export const products = [
  {
    id: 'changeorder',
    title: 'Change Order',
    configureCosts: true,
    overview: [],
    costs: {
      page: [
        {
          rate: 'dataEntry',
          hours: 4,
        },
      ],
      baseMonthly: [
        {
          monthly: 400,
        },
      ],
    },
    showSaaSCost: false,
    baseCosts: [],
  },
  {
    id: 'prebuilt',
    title: 'Pre-Built',
    overview: [
      'Quickest Delivery',
      'Brand Personalization',
      'Proven Functionality',
    ],
    baseCosts: [
      {
        title: 'Project Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 8,
          },
        ],
      },
      {
        title: 'Design',
        costs: [
          {
            rate: 'design',
            hours: 8,
          },
        ],
      },
      {
        title: 'Development',
        costs: [
          {
            rate: 'development',
            hours: 16,
          },
        ],
      },
      {
        title: 'Account Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 36,
          },
        ],
      },
    ],
    costs: {
      page: [
        {
          rate: 'dataEntry',
          hours: 4,
        },
      ],
      baseMonthly: [
        {
          monthly: 400,
        },
      ],
    },
    showSaaSCost: true,
    models: [
      {
        title: 'Aspen',
        image: '/images/design-center-header.png',
        examples: [
          {
            title: 'The Aspen Demo',
            href: 'https://aspen-demo-2019.builderpreviews.com',
          },
          {
            title: 'Glacier Custom Homes',
            href: 'https://glaciercustomhomes-2020.builderpreviews.com',
          },
          {
            title: 'Chris George Custom Homes',
            href: 'https://chrisgeorgecustomhomes-2020.builderpreviews.com',
          },
        ],
      },
      {
        title: 'Birch',
        image: '/images/design-center-header.png',
        examples: [
          {
            title: 'The Birch Demo',
            href: 'https://birch-demo-2019.builderpreviews.com',
          },
          {
            title: 'Kent Homes',
            href: 'https://kenthomes-2020.builderpreviews.com',
          },
        ],
      },
    ],
  },
  {
    id: 'semicustom',
    title: 'Semi-Custom',
    overview: [
      'Brand Personalization',
      'Proven Functionality',
      'Customizable Homepage',
    ],
    baseCosts: [
      {
        title: 'Project Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 12,
          },
        ],
      },
      {
        title: 'Design',
        costs: [
          {
            rate: 'design',
            hours: 20,
          },
        ],
      },
      {
        title: 'Development',
        costs: [
          {
            rate: 'development',
            hours: 40,
          },
        ],
      },
      {
        title: 'Account Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 48,
          },
        ],
      },
    ],
    costs: {
      page: [
        {
          rate: 'dataEntry',
          hours: 2,
        },
        {
          rate: 'design',
          hours: 1,
        },
        {
          rate: 'development',
          hours: 2,
        },
      ],
      baseMonthly: [
        {
          monthly: 400,
        },
      ],
    },
    showSaaSCost: true,
    models: [
      {
        title: 'Atrium',
        image: '/images/design-center-header.png',
        examples: [
          {
            title: 'Scott Felder',
            href: 'https://scottfelderhomes-2019.builderpreviews.com',
          },
          {
            title: 'Inspired Homes',
            href: 'https://inspired-homes-2020.builderpreviews.com',
          },
          {
            title: 'Rouse Chamberlin',
            href: 'https://rousechamberlin-2020.builderpreviews.com',
          },
        ],
      },
      {
        title: 'Balcony',
        image: '/images/design-center-header.png',
        examples: [
          {
            title: 'Lokal Homes',
            href: 'https://lokalhomes-2020.builderpreviews.com',
          },
          {
            title: '5th Avenue Construction',
            href: 'https://buildwih5th-2020.builderpreviews.com',
          },
          {
            title: 'Conneticut Valley',
            href: 'https://ctvalleyhomes-2019.builderpreviews.com/',
          },
        ],
      },
      {
        title: 'Conservatory',
        image: '/images/design-center-header.png',
        examples: [
          {
            title: 'Essential Demo',
            href: 'https://essential-demo-2019.builderpreviews.com',
          },
          {
            title: 'Falk Construction',
            href: 'https://falkconstructioninc-2019.builderpreviews.com/',
          },
          {
            title: 'Harney Homes',
            href: 'https://harneyhomesllc-2019.builderpreviews.com/',
          },
        ],
      },
    ],
  },
  {
    id: 'custom',
    title: 'Custom',
    overview: [
      'Branded Design',
      'Custom Functionality',
    ],
    baseCosts: [
      {
        title: 'Project Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 16,
          },
        ],
      },
      {
        title: 'Design',
        costs: [
          {
            rate: 'design',
            hours: 80,
          },
        ],
      },
      {
        title: 'Development',
        costs: [
          {
            rate: 'development',
            hours: 120,
          },
        ],
      },
      {
        title: 'Account Management',
        costs: [
          {
            rate: 'dataEntry',
            hours: 84,
          },
        ],
      },
    ],
    costs: {
      page: [
        {
          rate: 'dataEntry',
          hours: 2,
        },
        {
          rate: 'design',
          hours: 2,
        },
        {
          rate: 'development',
          hours: 2,
        },
      ],
      baseMonthly: [
        {
          monthly: 400,
        },
      ],
    },
    showSaaSCost: true,
    models: [
      {
        title: 'Custom',
        examples: [
          { title: 'Hunter Quinn Homes', href: 'https://hunterquinnhomes.builderpreviews.com' },
          { title: 'Mungo Homes', href: 'https://mungo.builderpreviews.com' },
          { title: 'American Legend', href: 'https://amlegendhomes-2019.builderpreviews.com' },
        ],
      },
    ],
  },
];

export const pages = [
  { title: 'Home Page', included: true },
  { title: 'Community Listings', included: true },
  { title: 'Community Detail', included: true },
  { title: 'Available Homes Listings', included: true },
  { title: 'Available Home Detail', included: true },
  { title: 'Floor Plan Listings', included: true },
  { title: 'Floor Plan Detail', included: true },
  { title: 'Photo Gallery', included: true },
  { title: 'Video Gallery', included: true },
  { title: 'Virtual Tour Gallery', included: true },
  { title: 'About Us', included: true },
  { title: 'Blog (Import from Wordpress Included)', included: true },
  { title: 'Contact Us', included: true },
  { title: 'Warranty Request', included: true },
  { title: 'Inquire Form', included: true },
  { title: 'Accessibility Feedback', included: true },
  { title: 'Privacy Policy', included: true },
  { title: 'Terms & Conditions', included: true },
  { title: 'Custom 404 Page', included: true },
  { title: 'Awards/Affiliations' },
  { title: 'Build On Your Lot' },
  { title: 'Customizing Your Home' },
  { title: 'Design Center' },
  { title: 'Energy Efficiency' },
  { title: 'Financing Info' },
  { title: 'Homeowner Tips' },
  { title: 'Meet the Team' },
  { title: 'Military Homebuyers' },
  { title: 'New vs. Used' },
  { title: 'Partners/Vendors' },
  { title: 'Process ' },
  { title: 'Realtor Contact' },
  { title: 'Refer a Friend' },
  { title: 'Remodeling' },
  { title: 'Testimonials' },
  { title: 'Warranty Info' },
  { title: 'FAQs' },
];

export const sections = [
  { title: 'Selected Product' },
  { title: 'Included Pages' },
  { title: 'Optional Pages' },
  {
    title: 'Special Sections & Features',
    features: [
      {
        title: 'Careers',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        title: 'Compare Products',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        uniqueId: 'platmaps',
        title: 'Interactive Community Plat Maps',
        costs: [
          { hours: 1, rate: 'dataEntry', price: 500 },
        ],
      },
      {
        uniqueId: 'poimaps',
        title: 'Community Point of Interest Maps',
        costs: [
          { hours: 1, rate: 'dataEntry', price: 1000 },
        ],
      },
      {
        title: 'Interactive Home Diagram',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
        perQuantity: true,
      },
      {
        title: 'Landing Page Template',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
        perQuantity: true,
      },
      {
        title: 'Lots',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        title: 'Model Homes',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        title: 'Mortgage Calculator',
        perQuantity: true,
        costs: [
          { rate: 'development', hours: 3.336 },
        ],
      },
      {
        title: 'My Favorites',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        title: 'Open Houses',
        costs: [
          { rate: 'dataEntry', hours: 10 },
        ],
      },
      {
        title: 'Special Offers',
        costs: [
          { rate: 'dataEntry', hours: 13.337 },
        ],
      },
      {
        title: 'Community Kiosk Template',
        monthly: 150,
        costs: [
          { rate: 'dataEntry', hours: 16.667 },
          { monthly: 150 },
        ],
      },
      {
        title: 'Community Kiosk Custom',
        // monthly: 150,
      },
    ],
  },
  {
    title: 'Incoming Data Feeds',
    other: {
      amount: true,
    },
    features: [
      {
        title: 'MLS',
        costs: [
          {
            rate: 'development',
            hours: 16.667,
          },
          {
            monthly: 250,
          },
        ],
      },
    ],
  },
  {
    title: 'Outgoing Data Feeds',
    other: {
      amount: true,
    },
    features: [
      {
        title: 'BDX',
        costs: [
          { rate: 'development', hours: 1, price: 1500 },
          { monthly: 150 },
        ],
      },
      {
        title: 'Zillow',
        costs: [
          { rate: 'development', hours: 1, price: 1500 },
          { monthly: 150 },
        ],
      },
    ],
  },
  {
    title: 'CRM Integrations',
    other: {
      amount: true,
    },
    features: [
      {
        title: 'Hubspot',
        costs: [
          { hours: 1, rate: 'dataEntry', price: 1000 },
        ],
      },
      {
        title: 'Lasso',
        costs: [
          { hours: 1, rate: 'dataEntry', price: 1000 },
        ],
      },
    ],
  },
  {
    title: 'Live Chat Integrations',
    other: {},
    features: [
      {
        title: 'UX Pro Live Chat',
        costs: [
          { monthly: 250 },
        ],
      },
    ],
  },
  {
    title: 'Content Loading Services',
    hideIncluded: true,
    features: [
      {
        title: 'Manual Blog Transfer',
        costs: [
          {
            rate: 'dataEntry',
            hours: 10,
          },
        ],
      },
      {
        title: 'Community Interactive Plat Maps',
        ifProduct: 'platmaps',
        costs: [
          {
            rate: 'dataEntry',
            hours: 2,
          },
        ],
        perQuantity: true,
        perLabel: 'community',
      },
      {
        ifProduct: 'poimaps',
        title: 'Community Point of Interest Maps',
        costs: [
          {
            rate: 'dataEntry',
            hours: 2,
          },
        ],
        perQuantity: true,
        perLabel: 'community',
      },
    ],
  },
];

export const priceGroups = [
  {
    title: 'Page Volume Discount',
    id: 'optional-pages',
    calculate: state => {
      const basePrice = state.selectedProduct?.costs?.page ?? 0;
      return basePrice;
    },
  },
];

export const discounts = [
  {
    title: 'Account Management Discount',
    id: 'inventory-data-entry',
    percentage: 100,
  },
  {
    title: 'Page Volume Discount',
    id: 'optional-pages',
    auto: true,
    calculate: (state, items) => {
      const pageCount = items.length;
      const costs = state.selectedProduct?.costs?.page ?? [];

      const basePrice = costs.reduce((acc, cost) => {
        let price = cost.price ?? 0;

        if (cost.rate && cost.hours) {
          price = (rates[cost.rate]?.billed ?? 0) * cost.hours;
        }

        return acc + price;
      }, 0);

      let pricePerPage = basePrice;

      if (pageCount > 4 && pageCount < 10) {
        pricePerPage = (basePrice * 4) / 5;
      } else if (pageCount >= 10) {
        pricePerPage = (((basePrice * 4) / 5) * 9) / 10;
      }

      pricePerPage = Math.round(pricePerPage / 10) * 10;

      return Math.abs((pageCount * basePrice) - (pageCount * pricePerPage));
    },
  },
];

export const showCosts = false;
export const sass = {
  multiplier: 1,
  termMonths: 24,
};
