/* eslint-disable import/prefer-default-export */
import
React,
{
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useApolloClient, gql } from '@apollo/client';

const authContext = createContext();

let singletonAccessToken;
export const getAuthToken = () => singletonAccessToken;

const USER_LOGIN = gql`
  mutation USER_LOGIN($email: String, $password: String) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

const GET_SELF = gql`
  query GET_SELF {
    me {
      _id
      name
    }
  }
`;

export function useProvideAuth() {
  const [user, setUser] = useState(false);
  const [error, setError] = useState(null);
  const client = useApolloClient();

  const setToken = async (token, allowError = false) => {
    window.localStorage.setItem('accessToken', token);
    singletonAccessToken = token;

    try {
      const { data: { me = {} } = {} } = await client.query({
        query: GET_SELF,
      });
      setUser(me);
    } catch (e) {
      setUser(null);
      if (allowError && !e.message.match(/AuthenticationError/)) {
        throw e;
      }
    }
  };

  const login = async (email, password) => {
    try {
      const { data: { login: { accessToken } = {} } = {} } = await client.mutate({
        mutation: USER_LOGIN,
        variables: { email, password },
      });
      await setToken(accessToken);
    } catch (e) {
      setError(e.message);
    }
  };

  const logout = () => {
    window.localStorage.removeItem('accessToken');
    singletonAccessToken = undefined;

    setUser(null);
    client.clearStore();
  };

  useEffect(() => {
    if (!singletonAccessToken) {
      const token = window.localStorage.getItem('accessToken');

      if (token) {
        setToken(token, true);
      } else {
        setUser(null);
      }
    }
  });

  return {
    user,
    login,
    logout,
    error,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useAuth() {
  return useContext(authContext);
}
